import AWS from 'aws-sdk'
import {Dispatch, SetStateAction} from 'react'
export const BASE_S3_URL = `https://stockpile-files.s3.ap-south-1.amazonaws.com/`
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
})

const s3 = new AWS.S3({
  params: {Bucket: process.env.REACT_APP_AWS_BUCKET},
  region: process.env.REACT_APP_AWS_REGION,
})

export const uploadFiles = async (
  filename: string,
  body: File,
  setProgress?: Dispatch<SetStateAction<number>> | null,
  changeProgress?: null | ((progress: number) => void)
) => {
  let progress = 0
  const upload = s3
    .putObject({
      Bucket: process.env.REACT_APP_AWS_BUCKET as string,
      Key: filename,
      Body: body,
      BucketKeyEnabled: false,
    })
    .on('httpUploadProgress', (evt) => {
      progress = (evt.loaded * 100) / evt.total
      if (setProgress) setProgress(progress)
      if (changeProgress) changeProgress(progress)
    })
    .promise()

  return upload

  // upload
  //   .then((e) => {
  //     onSuccess()
  //     return true
  //   })
  //   .catch((e) => {
  //     onError()
  //     return e
  //   })
}
